import firebase from 'firebase/app';
import 'firebase/functions';

export const netFirebase = {
    _initted: false,
    _app_props: null,
    _firebase_app: null,

    Init: function (appProps = null) {
        //logger('netFirebase.Init');
        if (netFirebase._initted) {
            return;
        }
        netFirebase._initted = true;
        netFirebase._app_props = appProps;

        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
            appId: process.env.REACT_APP_FIREBASE_APPID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
        };
        netFirebase._firebase_app = firebase.initializeApp(firebaseConfig);
    }

}