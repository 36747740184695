import React from 'react';
import {isIframe} from "../lib/Utility";

const headerLogo = process.env.REACT_APP_HEADER_LOGO;
const headerLogoBgColor = process.env.REACT_APP_HEADER_LOGO_BG_COLOR ? process.env.REACT_APP_HEADER_LOGO_BG_COLOR : 'transparent';
const headerLogoInIframe = process.env.REACT_APP_HEADER_LOGO_IN_IFRAME && process.env.REACT_APP_HEADER_LOGO_IN_IFRAME === 'true';

const Header = (props) => {
    const header = (!isIframe() || headerLogoInIframe) && headerLogo !== 'undefined' && headerLogo !== '' ?
                    <div className="mbsc-align-center" style={{maxWidth: "500px", padding: "1em", margin: "auto"}}>
                        <img className="mbsc-media-fluid" src={headerLogo} alt={process.env.REACT_APP_APP_COMPANY} />
                    </div>
                    : null;

    return (
        <div className="mbsc-grid net-form-header">
            <div className="mbsc-row mbsc-justify-content-center" style={{backgroundColor: headerLogoBgColor}}>
                <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6">
                    {header}
                </div>
            </div>
        </div>
    )
}

export default Header;