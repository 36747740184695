import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import mobiscroll from "@mobiscroll/react";
import Header from "../../components/Header";

export default class Terms extends Component {

    render() {
        return (
            <mobiscroll.Form
                className="net-form-width-lg"
            >

                <Helmet>
                    <title>Terms &amp; Refund Policy - {process.env.REACT_APP_APP_TITLE}</title>
                </Helmet>

                <Header />

                <h3>Terms and Refund Policy</h3>
                <div className="mbsc-txt-s">
                    {process.env.REACT_APP_APP_COMPANY && process.env.REACT_APP_APP_ABN ?
                        <p>{process.env.REACT_APP_APP_COMPANY} (ABN {process.env.REACT_APP_APP_ABN})</p>
                        : null}

                    <p>These Terms &amp; Conditions apply to all products, services and websites owned or provided by us ("Service").</p>

                    {process.env.REACT_APP_APP_COMPANY_SERVICE_SUMMARY ?
                        <React.Fragment>
                            <h4>Summary of Service</h4>
                            <p>{process.env.REACT_APP_APP_COMPANY_SERVICE_SUMMARY}</p>
                        </React.Fragment>
                        :null}

                    <h4>Price, payment and service provision</h4>
                    <ul>
                        <li>All prices are quoted in Australian dollars and inclusive of applicable taxes, if any, unless specified otherwise.</li>
                        <li>Surcharges may apply to some payment types and online payment processing. Any surcharge set by us will not exceed the cost of processing the payment.</li>
                    </ul>

                    <h4>Refund policy</h4>
                    <ul>
                        <li>We are not required by law to provide a refund or replacement if you change your mind.</li>

                        <li>Full refunds will be available in the instance of major faults in order to comply with Australian Consumer Law.</li>
                        <li>Refunds will be reimbursed to your original payment method.</li>
                    </ul>

                    <h4>Disclaimers</h4>
                    <ul>
                        <li>We may make improvements or changes to our website, the content, or to any Service, at any time and without advance notice.</li>
                        <li>You are advised that content of our website may include technical inaccuracies or typographical errors. This is inevitable in any large Service. We would be grateful if you bring to our immediate attention, any that you find.</li>
                        <li>We give no warranty and make no representation, express or implied, as to:
                            <ol type="i">
                                <li>the adequacy or appropriateness of the Service for your purpose;</li>
                                <li>the truth of any content on our website published by someone other than us;</li>
                                <li>any implied warranty or condition as to merchantability or fitness of the Service for a purpose other than that for which our Service is commonly used;</li>
                            </ol>
                        </li>
                        <li>Our website contains links to other websites outside our power and control. You acknowledge and agree that we shall not be liable in any way for the content of any such linked website, nor for any loss or damage arising from your use of any such website.</li>
                        <li>We are not liable in any circumstances for special, indirect or consequential loss or any damages whatsoever resulting from loss of use, loss of data or loss of revenues or profits, whether in an action of contract, negligence or otherwise, arising out of or in connection with your use of our Service.</li>
                        <li>We are not liable in any circumstances for any injury, damage or consequential loss whatsoever associated with unwise, inappropriate or illegal use of our Service.</li>
                        <li>In any event, including the event that any term or condition or obligation on our part (“Implied Term”) is implied into these conditions by law, then our liability is limited to the maximum extent permitted by law, to the value of the Service you have purchased.</li>
                    </ul>

                    <h4>System Security</h4>
                    <ul>
                        <li>You agree that you will not, and will not allow any other person to violate or attempt to violate any aspect of the security of our website.</li>
                        <li>You may not use any software tool for the purpose of extracting data from our website.</li>
                        <li>You understand that any such violation is unlawful and that any contravention of law may result in criminal prosecution.</li>
                    </ul>

                    <h4>Intellectual Property Rights</h4>
                    <ul>
                        <li>The material contained on our website is protected by copyright. Except to the extent permitted by relevant copyright legislation, you must not use, copy, modify, transmit, store, publish or distribute the material on our website, or create any other material using material on our website, without obtaining our prior written consent.</li>
                        <li>The products, Services, Apps, technology and processes contained in our website may be the subject of other intellectual property rights owned by us. No licence is granted in respect of those intellectual property rights other than as set out in these Terms. Your use of our App must not in any way infringe the intellectual property rights of any person.</li>
                        <li>We will defend the intellectual property rights in connection with our Services and our website, including copyright whether provided by us or by any other content provider (including copyright in text, graphics, logos, icons, images, audio clips, digital downloads, data, and software).</li>
                        <li>We also claim copyright in the designs and compilation of all content of our website and shall remain the sole property of us and / or the other content provider.</li>
                        <li>Except as set out below, you may not copy, modify, publish, transmit, transfer or sell, reproduce, create derivative works from, distribute, perform, display, or in any way exploit any of the content of our website, in whole or in part.</li>
                        <li>You may not use our name or logos or trade marks or any other content on any website of yours or that of any other person.</li>
                        <li>Subject to the other terms of this agreement, you may use the content of our website only for your own personal use, provided that you maintain all copyright and other notices contained in it. You may not store electronically any significant portion of any content of our website.</li>
                    </ul>

                    <h4>Indemnity</h4>
                    <ul>
                        <li>You agree to indemnify us against any claim or demand, including reasonable lawyers’ fees, made by any third party due to or arising in any way out of your use of our App, your posting any material, or the infringement by you, or by any other person using your computer, of any intellectual property or other right of any person.</li>
                    </ul>

                    <h4>Miscellaneous provisions</h4>
                    <ul>
                        <li>Nothing in this agreement or on our website shall confer on any third party any benefit or obligation.</li>
                        <li>If any of these terms is at any time held to be void, invalid or unenforceable, then it shall be treated as changed or reduced, only to the extent minimally necessary to bring it within the laws of that jurisdiction and to prevent it from being void and it shall be binding in that changed or reduced form. Subject to that, each provision shall be interpreted as severable and shall not in any way affect any other of these terms.</li>
                        <li>No waiver by us, in exercising any right, power or provision in this agreement shall operate as a waiver of any other right or of that same right at a future time; nor shall any delay in exercise of any power or right be interpreted as a waiver.</li>
                        <li>In the event of a dispute arising out of or in connection with these terms or any contract between you and us, then you agree to attempt to settle the dispute by engaging in good faith with us in a process of mediation before commencing arbitration or litigation.</li>
                        <li>We are not liable for any breach of our obligations resulting from causes beyond our reasonable control including strikes of our own employees.</li>
                        <li>This agreement shall be governed by and construed in accordance with the laws of Western Australia.</li>
                    </ul>

                    <p className="mbsc-txt-muted">Last updated: 30th December 2020</p>
                </div>
            </mobiscroll.Form>
        );
    }
}