import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import mobiscroll from "@mobiscroll/react";
import Header from "../../components/Header";

export default class Privacy extends Component {

    render() {
        return (
            <mobiscroll.Form
                className="net-form-width-lg"
            >

                <Helmet>
                    <title>Privacy Policy - {process.env.REACT_APP_APP_TITLE}</title>
                </Helmet>

                <Header />

                <h3>Privacy Policy</h3>
                <div className="mbsc-txt-s">
                    {process.env.REACT_APP_APP_COMPANY && process.env.REACT_APP_APP_ABN ?
                        <p>{process.env.REACT_APP_APP_COMPANY} (ABN {process.env.REACT_APP_APP_ABN})</p>
                        : null}

                    <p>This Privacy Policy applies to all products, services and websites owned or provided by us ("Service"). We are extremely concerned to protect your privacy and confidentiality. If you have any questions regarding this Privacy Policy then please contact us.</p>

                    <h4>Information we collect</h4>
                    <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
                        {/*Information that we may collect include the following:*/}
                    </p>
                    <p>If you do not want us to collect, store or use your information, then you should not use our Service.</p>
                    <p>Our Service may use third party provider services that may also collect information: Google Analytics, Google Firebase, Google Maps, Net Payments, Sentry, Hotjar.</p>

                    <h4>How we use your information</h4>
                    <p>Information provided by you may be used:</p>
                    <ul>
                        <li>To provide you with our Service</li>
                        <li>To personalise your experience when using our Service</li>
                        <li>To maintain our accounts</li>
                        <li>To answer your enquiries</li>
                        <li>To verify your identity for security purposes</li>
                        <li>To contact you regarding news and promotions on offer</li>
                        <li>To improve our Service</li>
                    </ul>
                    <p>Your information will never be shared with third parties, except in situations where a third parties services are employed by us in operating and conducting business, and under the condition that they too treat the information confidentially. Access to data is restricted to our employees and contractors on a need-to-know basis. We do not sell your data.</p>

                    <h4>How we protect your information</h4>
                    <ul>
                        <li>We use Strong SSL Encryption to transmit sensitive data.</li>
                        <li>We use a PCI Compliant Payment Gateway to process credit/debit card details and payments. Credit/Debit Card data is encrypted and may be stored in the Payment Gateway providers PCI Compliant data vault, is only accessible by authorised personnel, never passes through our servers, and is never stored on our servers or in our databases.</li>
                        {/*<li>Your account may be password protected by a password of your choosing. It is your responsibility to use a unique, strong password and to keep this password confidential.</li>*/}
                        <li>Security of any data transmitted through the Internet can never be 100% guaranteed, therefore you use our products, services, and website entirely at your own risk.</li>
                    </ul>
                    <h4>How to review, change or remove your data</h4>
                    {/*<p>You may log into your account at any time to review and update your data. To close your account and remove your data contact support from the email address that is registered to your account. Verified requests will be responded to within 30 days. Inactive accounts may be automatically disabled or erased according to law. For any other queries please contact support.</p>*/}
                    <p>You may email us your request.</p>
                    <h4>Compulsory disclosure</h4>
                    <p>Like any business, we will be obliged to divulge your personal information if requested by a government agency with the proper authorisation, such as a court order.</p>
                    <h4>Third party Websites</h4>
                    <p>We are not responsible or liable for the content or activities of any third party Websites that we may link to or consume data from. If you have any questions in this regard, or believe that a Website that we are linked to is not within the context of the Service we offer, then please contact us.</p>
                    <h4>Changes to this Privacy Policy</h4>
                    <p>This Privacy Policy may be altered from time to time and the changes will be published on this page. It is recommended that you check this page periodically for any changes.</p>
                    <p className="mbsc-txt-muted">Last updated: 30th December 2020</p>
                </div>
            </mobiscroll.Form>
        );
    }
}