// https://www.npmjs.com/package/react-credit-cards
// https://www.visa.com.au/run-your-business/accept-visa-payments/posguidelines.html
// https://brand.mastercard.com/brandcenter/mastercard-brand-mark/downloads.html
// https://merchant-supplies.americanexpress.com/?locale=en_GB#/catalog/All
import React from 'react';
import mobiscroll from "@mobiscroll/react";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
//import { logger, updateObject } from "../../lib/Utility";

export default class CardPaymentForm extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: '',
            issuer: 'unknown'
        };

        this.handleCardPaymentFormStateChange = this.handleCardPaymentFormStateChange.bind(this);
    }

    handleCardPaymentFormStateChange = () => {
        if (this.props.handleCardPaymentFormStateChange !== undefined && typeof(this.props.handleCardPaymentFormStateChange) === 'function') {
            this.props.handleCardPaymentFormStateChange(this.state);
        }
    }

    handleInputFocus = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        if (fieldName === 'expiry_month' || fieldName === 'expiry_year') {
            fieldName = 'expiry';
        }
        this.setState({ focus: fieldName });
    }

    handleInputChange = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        let fieldValue = e.target.value;
        //logger(this.state.expiry);
        if (fieldName === 'number') {
            /*
            const valArray = fieldValue.split(' ').join('').split('');
            let valSpace = fieldValue.split("");
            if(valSpace[valSpace.length-1] == ' '){
                let valSpaceN = valSpace.slice(0, -2);
                fieldValue = valSpaceN.join("");
            } else if(valArray.length % 4 === 0 && valArray.length <= 15) {
                fieldValue = fieldValue + "*";
            }
            console.log(fieldValue+"|")
            if (this.state.issuer === 'amex') {

            }
             */
        } else if (fieldName === 'name') {
            fieldValue = fieldValue.toUpperCase();
        } else if (fieldName === 'expiry_month') {
            fieldName = 'expiry';
        } else if (fieldName === 'expiry_year') {
            fieldName = 'expiry';
            if (this.state.expiry.length >= 2) {
                fieldValue = this.state.expiry.substring(0,2) + fieldValue;
            } else if (this.state.expiry.length === 1) {
                fieldValue = "0" + this.state.expiry.substring(1,1) + fieldValue;
            } else {
                fieldValue = "09" + fieldValue;
            }
        }
        this.setState({ [fieldName]: fieldValue }, this.handleCardPaymentFormStateChange);
    }

    cardNumberChangeCallback = (type, isValid) => {
        // type ({ issuer: 'visa', maxLength: 19 }), isValid ({boolean})
        //console.log(type, isValid);
        if (type.issuer !== this.state.issuer) {
            this.setState({ issuer: type.issuer });
        }
        /*
        let cardNumber = this.state.number.replace(/[A-Za-z]| /g, '');
        console.log(cardNumber, cardNumber.length);
        if (['amex', 'dinersclub'].indexOf(type.issuer) !== -1) {
            var format = [0, 4, 10];
            var limit = [4, 6, 5];
            cardNumber = "".concat(cardNumber.substr(format[0], limit[0]), " ").concat(cardNumber.substr(format[1], limit[1]), " ").concat(cardNumber.substr(format[2], limit[2]));
        } else if (cardNumber.length > 16) {
            var _format = [0, 4, 8, 12];
            var _limit = [4, 7];
            cardNumber = "".concat(cardNumber.substr(_format[0], _limit[0]), " ").concat(cardNumber.substr(_format[1], _limit[0]), " ").concat(cardNumber.substr(_format[2], _limit[0]), " ").concat(cardNumber.substr(_format[3], _limit[1]));
        } else {
            for (var i = 1; i < 16 / 4; i++) {
                var space_index = i * 4 + (i - 1);
                console.log('space_index',space_index)
                if (cardNumber.length > space_index) {
                    cardNumber = "".concat(cardNumber.slice(0, space_index), "*").concat(cardNumber.slice(space_index));
                }
            }
        }
        //cardNumber = cardNumber.trim();
        console.log(cardNumber +"|"+this.state.number);
        if (cardNumber !== this.state.number) {
            //this.setState({ number: cardNumber }, this.handleCardPaymentFormStateChange);
        }
        */
    }

    render = () => {
        return (
            <div id="CardPaymentForm">
                <div className="mbsc-grid">

                    <div className="mbsc-row">

                        <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6">
                            <mobiscroll.Input
                                inputStyle={this.props.inputStyle}
                                labelStyle={this.props.labelStyle}
                                type="tel"
                                name="cc-number"
                                autoComplete="cc-number"
                                placeholder=""
                                data-fieldname="number"
                                maxLength={19}
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                            >Card Number</mobiscroll.Input>

                            <mobiscroll.Input
                                inputStyle={this.props.inputStyle}
                                labelStyle={this.props.labelStyle}
                                type="text"
                                name="cc-name"
                                autoComplete="cc-name"
                                data-fieldname="name"
                                maxLength={100}
                                style={{textTransform: "upperCase"}}
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                            >Cardholder Name</mobiscroll.Input>


                            <div className="mbsc-row">

                                <div className="mbsc-col-6" style={{paddingRight: 0}}>
                                    <mobiscroll.Input
                                        inputStyle={this.props.inputStyle}
                                        labelStyle={this.props.labelStyle}
                                        type="tel"
                                        name="cc-exp"
                                        autoComplete="cc-exp"
                                        placeholder="MM/YY"
                                        data-fieldname="expiry"
                                        maxLength={5}
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                    >Expiry (MM/YY)</mobiscroll.Input>
                                </div>

                                {/*
                                <div className="mbsc-col-6">
                                    <mobiscroll.Input
                                        inputStyle={this.props.inputStyle}
                                        labelStyle={this.props.labelStyle}
                                        type="tel"
                                        name="cc-exp-month"
                                        autoComplete="cc-exp-month"
                                        placeholder="MM"
                                        data-fieldname="expiry_month"
                                        maxLength={2}
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                    >Expiry Month</mobiscroll.Input>
                                </div>

                                <div className="mbsc-col-6">
                                    <mobiscroll.Input
                                        inputStyle={this.props.inputStyle}
                                        labelStyle={this.props.labelStyle}
                                        type="tel"
                                        name="cc-exp-year"
                                        autoComplete="cc-exp-year"
                                        placeholder="YY"
                                        data-fieldname="expiry_year"
                                        maxLength={2}
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                    >Expiry Year</mobiscroll.Input>
                                </div>
                                */}
                                <div className="mbsc-col-6" style={{paddingLeft: 0}}>
                                    <mobiscroll.Input
                                        inputStyle={this.props.inputStyle}
                                        labelStyle={this.props.labelStyle}
                                        type="tel"
                                        name="cc-csc"
                                        autoComplete="cc-csc"
                                        placeholder="CSC"
                                        data-fieldname="cvc"
                                        maxLength={4}
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                    >Security Code</mobiscroll.Input>
                                </div>
                            </div>
                        </div>

                        <div className="mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6 hide-on-small-screen">
                            <Cards
                                cvc={this.state.cvc}
                                expiry={this.state.expiry}
                                focused={this.state.focus}
                                name={this.state.name}
                                number={this.state.number}
                                locale={{valid: 'EXPIRY'}}
                                placeholders={{name: 'CARDHOLDER NAME'}}
                                acceptedCards={this.props.merchant.cards}
                                callback={this.cardNumberChangeCallback}
                            />
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}