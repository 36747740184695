export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const logger = (str, force) => {
    if (typeof console === "object") {
        force = force || false;
        //if (ConfigGlobal.Debug() || force) {
            console.log(str);
        //}
    }
}

// export const nl2br = (str) => {
//     if (typeof str === 'undefined' || str === null) {
//         return '';
//     }
//     return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
// }

export const isNumeric = (str) => {
    if (typeof str === "number") return true; // we only process strings!
    if (typeof str != "string") return false; // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const isProduction = () => {
    return process.env.NODE_ENV === 'production';
}

export const isCordova = () => {
    /*eslint-disable no-undef*/
    return (typeof cordova === 'object');
    /*eslint-enable no-undef*/
}

export const isGoogleMapsLoaded = () => {
    /*eslint-disable no-undef*/
    return (typeof google === 'object' && typeof google.maps === 'object');
    /*eslint-enable no-undef*/
}

export const isAppleSigninLoaded = () => {
    /*eslint-disable no-undef*/
    return isCordova() && (typeof window.cordova.plugins === 'object' && typeof window.cordova.plugins.SignInWithApple === 'object');
    /*eslint-enable no-undef*/
}

export const openWindow = (url, target, features) => {
    return window.open(url, target, features);
}

export const isIframe = () => {
    return !isCordova() && (window.top !== window.self);
}

export const attachDeviceInfoToData = (data) => {
    //attaches device info to the array that is passed in
    data.device_useragent = navigator.userAgent;
    //logger("|attachDeviceInfoToData| device_useragent="+data.device_useragent);
    if(isCordova() && typeof device !== 'undefined') {
        var device_keys = ['cordova','platform','uuid','version','model'];
        for(var i=0;i<device_keys.length;i++) {
            var key = device_keys[i];
            /*eslint-disable no-undef*/
            if(device[key]) {
                var data_key = 'device_'+key;
                data[data_key] = device[key];
                //logger("|attachDeviceInfoToData| "+data_key+'='+device[key]);
            }
            /*eslint-enable no-undef*/
        }
    }
}

export const unixDatetimeToDate = (datetime) => {
    if (datetime) {
        const jsdate = new Date(datetime);
        // YYYY-MM-DD
        let date = jsdate.getFullYear()+'-'+`${jsdate.getMonth() + 1}`.padStart(2,0)+'-'+`${jsdate.getDate()}`.padStart(2,0);
        return date;
    };
    return null;
};

export const unixDatetimeToDateAuEng = (datetime) => {
    if (datetime) {
        const jsdate = new Date(datetime);
        // DD/MM/YYYY
        let date = `${jsdate.getDate()}`.padStart(2,0)+'/'+`${jsdate.getMonth() + 1}`.padStart(2,0)+'/'+jsdate.getFullYear();
        return date;
    };
    return null;
};

export const unixDatetimeToTime = (datetime) => {
    if (datetime) {
        const jsdate = new Date(datetime);
        // HH:mm
        let time = `${jsdate.getHours()}`.padStart(2,0)+':'+`${jsdate.getMinutes()}`.padStart(2,0);
        return time;
    };
    return null;
};

export const unixUtcTimeSec = () => {
    return parseInt(new Date().getTime() / 1000, 10);
}

export const unixUtcTimeMs = () => {
    return parseInt(new Date().getTime(), 10);
}

export const unixUtcTimeOffsetMins = (offsetMins) => {
    let t = unixUtcTimeSec();
    t = t + (offsetMins*60);
    return t;
}

export const jsDateToday = () => {
    let d = new Date();
    d.setSeconds(0);
    d.setHours(0);
    d.setMinutes(0);
    d.setMilliseconds(0);
    return d;
}

export const hashCode = s => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0)