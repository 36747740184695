import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { logger, isCordova } from './lib/Utility';
import './index.css';
import App from './App';
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import {netFirebase} from "./lib/Firebase";

const app = (
    <HashRouter>
        <App />
    </HashRouter>
);

const appRoot = document.getElementById('root');
appRoot.setAttribute('notranslate', true);

const renderApp = () => {
    if (isCordova()) {
        // cordova is now fully loaded and safe to use device APIs
        /*eslint-disable no-undef*/
        window.open = cordova.InAppBrowser.open;
        /*eslint-enable no-undef*/
    }
    netFirebase.Init();
    ReactDOM.render(app, appRoot);
    // registerServiceWorker();
}

if (isCordova()) {
    // wait for cordova to fully load
    logger('render waiting for deviceready');
    document.addEventListener('deviceready', renderApp, false);
} else {
    logger('render immediately');
    renderApp();
}